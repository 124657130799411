body, html {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff6ea;
  position: relative;
}

#root {
  position: relative;
  z-index: 100;
  display: flex;
  min-height: 100%;
}


.app {
  display: flex;
  flex-grow: 100;
  padding-top: 40px;
}


.fragment-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: -40px;
  flex-direction: column;
}

.fragment-box {
  display: flex;
  position: relative;
}


.fragment {
  flex: 0 0 auto;
  height: 80px;
  width: 120px;
  cursor: pointer;
}

.fragment_disabled {
  background-color: #c9c7c7;
}


.main {
  flex-grow: 10;
}



.demoWrapper {
  display: flex;
  position: relative;
  flex-grow: 10;
}

.scroll-left {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 40px;
}

.scroll-right {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
}


.logo {
  height: calc(300px + min(200px, 100vh / 8));
  margin: 0 auto;
  display: block;
  padding-top: min(200px, 100vh / 8);
}


.logo-c {
  padding-bottom: 100px;
  margin: 0 auto;
}

.logoName {
  top: 0;
  font-size: 32px;
  font-weight: bold;
  position: absolute;
  left: 10px;
  text-decoration: none;
  margin: 10px;
  z-index: 100;
  color: #ffd58f
}

.logoNameSpan {
  color: #fcb33d
}

.actions {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-size: 24px;
  text-transform: uppercase;
  cursor: pointer;
}

.actions_item {
  color: #603909;
  text-decoration: none;
  text-align: center;
  margin-top: 15px
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
}


#root-canvas {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}


@media (max-aspect-ratio: 8/10) {
  #root {
    min-height: 0 !important;
  }
  .app {
    min-height: 100% !important;
    flex-direction: column !important;
  }

  .fragment-container {
    margin-top: 0 !important;
    flex-direction: row !important;
    justify-content: space-around !important;
  }


  .demoWrapper {
    aspect-ratio: 8 / 10 !important;
  }

  .fragment-box {
    display: flex;
    position: relative;
  }

}

.constructor-H {
  position: absolute;
  right: 50px;
  top: calc(50% - 70px);
  /*border-left: 4px solid #ffd58f;*/
  height: 60px;
  display: flex;
  flex-direction: column;
}

.constructor-H button {
  border: none !important;
  outline: none !important;
  background: none !important;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  margin: 0 10px
}

.constructor-W {
  position: absolute;
  right: calc(50% - 30px);
  bottom: 10px;
  /*border-top: 4px solid #ffd58f;*/
  height: 60px;
  display: flex;
  flex-direction: row;
}

.constructor-W button {
  border: none !important;
  outline: none !important;
  background: none !important;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  margin: 0 10px
}


.H-minus {
  margin-top: 10px;
}

.H-minus, .H-plus, .W-plus, .W-minus {
  color: #603909;
}


.constructor-item-cell {
  border-radius: 6px;
  flex-grow: 1;
  cursor: pointer;
  position: relative;
}
.constructor-item-cell:hover {
  outline: 3px solid #ffd58f50;
}

.cell_active {
  background: #ffd58f;
}



.constructor-delete {
  display: flex;
  position: absolute;
  margin-top: 20px;
  /*height: 30px;*/
  border: none;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #603909;
  /*background: #ffd6c1;*/
  background: none;
  padding: 5px;
  /*border: 4px solid #ffd6c1;*/
  border-radius: 5px;
}

.constructor-delete-icon {
  font-size: 20px;
  position: absolute;
  top: 3px;
  left: -30px;
}

.dimensions {
  color: #603909;
  text-transform: uppercase;
  padding-bottom: 20px;
  font-weight: 600;
}

.dimensions input {
  width: 40px;
  text-align: center;
  color: #603909;
  font-size: 16px;
  font-weight: 600;
  background: none;
  border: none;
}

.constructor-view-icon {
  padding-top: 20px;
  color: #603909;
  font-size: 20px;
  margin: 0 0 0 auto;
  /*position: absolute;*/
  /*bottom: 80px;*/
}

.cell-slice {
  top: 0;
  background: #ffd58f50;
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  right: 0;
}


.fire {
  height: 30px;
  padding: 20px;
  color: #ff7c00;
  cursor: pointer;
}


.app-constructor {
  padding-top: 60px;
  flex-grow: 1;
}


.constructor-tabs {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}


.constructor-tabs button {
  background: none;
  border: none;
  color: #603909;
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 20px;
  border-bottom: 2px solid #00000000;
}


.constructor-tabs button.active {
  border-bottom: 2px solid rgba(96, 57, 9, 0.56);
}





.from-constructor {

}