.constructor__solution-float-action {
    position: fixed;
    right: 20px;
    bottom: 20px;
    border-radius: 20px;
    padding: 10px;
    width: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
}
